<template>
  <div class="card-custom" v-if="ready">
    <b-card class="mt-3">
      <b-row class="text-center"> 
        <b-col md="3">
          <b-form-group label="Partenaire">
            <treeselect v-model="filters['id']" :multiple="true" :options="params.Partenaire"/>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Statut partenaire">
            <treeselect v-model="filters['status_id']" :multiple="true" :options="params.PartenaireStatus"/>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Mois">
            <b-form-input type="month" v-model="filters['mois']"></b-form-input>
          </b-form-group>
        </b-col>        
      </b-row>
      <b-row class="text-center mt-3">
        <b-col md="3" offset-md="4">
          <b-button  v-show="loaderSearch === false" variant="primary" @click="datatableInit()">
            Recherche
          </b-button>
          <b-button  v-show="loaderSearch === true" variant="primary">
            <i class="fas fa-spinner fa-spin"></i>
          </b-button>
          <b-button class="ms-2" variant="success" href="/#/admin/partenaire/nouveau" v-if="$store.api.user.role === 1">Ajouter</b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3">
      <div class="">
        <h4 class="card-title d-inline">Liste des partenaires ({{datatable.data.length}})</h4>
      </div>
      <b-table ref="datalist" stacked="md" striped hover :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
        <template #cell(date_create)="data">
          {{$store.api.timestampToDate(data.value, false)}}
        </template>
        <template #cell(user.nom)="data">
          {{data.value}} {{data.item.user.prenom}}
        </template>
        <template #cell(type_commission)="data">
          {{$store.api.getParam(params.CommissionType, data.value).titre}}
        </template>
        <template #cell(montant_ht)="data">
          {{data.item.type_commission_fixe != null ? data.item.type_commission_fixe+'€' : ''}} {{data.item.type_commission_lineaire != null ? '+ '+data.item.type_commission_lineaire+'%' : ''}}
        </template>
        <template #cell(status_id)="data">
          <b-button size="sm" :variant="$store.api.getParam(params.PartenaireStatus, data.value).color">{{$store.api.getParam(params.PartenaireStatus, data.value).titre}}</b-button>
        </template>
        <template #cell(action)="data">
          <b-button size="sm" variant="indigo" class="text-white" :href="'/#/admin/partenaire/detail/'+data.item.id" style="background: #6610f2;"><i class="fas fa-eye"></i></b-button>
        </template>
      </b-table>
      <b-pagination align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
    </b-card>
  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "",
  computed:{    
  },
  components: {
    Treeselect
  },
  data: () => ({
    ready: false,
    loaderSearch:false,
    params:{
      CommissionType:[]
    },
    fields: [
      {
        key: 'id',
        label: 'Ref.',
        sortable:true,
      },
      {
        key: 'date_create',
        label: "Date d'entrée",
        sortable:false,
      },
      {
        key: 'raison_social',
        label: "Raison sociale",
        sortable:false,
      },
      {
        key: 'user.nom',
        label: "Nom",
        sortable:false,
      },
      {
        key: 'type_commission',
        label: "Type commission",
        sortable:false,
      },
      {
        key: 'montant_ht',
        label: "Montant commission",
        sortable:false,
      },
      {
        key: 'count',
        label: "Souscription Pautions",
        sortable:false,
      },
      {
        key: 'apporteur.raison_social',
        label: "Apporteur affilie",
        sortable:false,
      },
      {
        key: 'status_id',
        label: "Statut",
        sortable:false,
      },
      {
        key: 'action',
        label: "Fiche",
        sortable:false,
      }
    ],
    datatable:{
      currentPage: 1,
      limitPage: 20,
      totalRow: 0,
      data: [],
    },
    sort:{
      key: 'id',
      value: 'DESC'
    },
    filters:{
      "id":null,
      "status_id":null,
      "min_date_create":null,
      "max_date_create":null
    }
  }),
  methods: {
    datatableInit(){
      if(this.loaderSearch === true){
        return false;
      }
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.loaderSearch = true;
      this.$store.api.ajax('/partenaire/liste', params, (res) => {
        this.datatable = res;
        this.loaderSearch = false;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    formatDate(date){
      var tmp = date.split('-');
      if(tmp.length === 3){
        return tmp.reverse().join('/')
      }else{
        return '';
      }
    },
  },
  beforeMount() {
    this.$store.api.ajax('/partenaire/params', null, (res) => {
      if(res.status === true){
        res.data.Partenaire.forEach((item) => {
          item.label = item.raison_social;
        });
        res.data.PartenaireStatus.forEach((item) => {
          item.label = item.titre;
        });
        this.params = res.data
        this.ready = true;
      }
    })
  },
  mounted() {
    this.datatableInit();
  },
}
</script>
<style>
</style>
